// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
ActiveStorage.start()

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// css
import '../stylesheets/application.scss'

// vendor
import '@fortawesome/fontawesome-free/js/fontawesome'
import 'script-loader!jquery-validation'
import 'script-loader!jquery-validation/dist/localization/messages_el'
import 'script-loader!ez-plus/src/jquery.ez-plus'
import 'script-loader!../src/mobile-menu'

$(document).on('turbo:load', function(){
    init_validation();


    function init_validation(){
        $('.simple_form:not(dont-validate)').validate({
            ignore: [],
            invalidHandler: function(event, validator) {
                $.each(validator.errorList, function(index, error){
                    var element              = error.element
                    var content_tabs_wrapper = $(element).closest('.content-tabs-wrapper')
                    var all_contents         = content_tabs_wrapper.find('.tab-content')
                    var element_content      = $(element).closest('.tab-content')

                    if(content_tabs_wrapper){

                        $('.tab-item > a').removeClass('active')
                        $('.tab-item[type='+element_content.attr('type')+'] > a').addClass('active')
                        if(element_content.is(":visible"))
                            all_contents.not(element_content).hide()
                        else{
                            all_contents.hide()
                            element_content.show();
                        }

                        return false;
                    }
                });
            }
        });
    }
})